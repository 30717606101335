import { HTTP } from ".";

// export async function all() {
//   return (await HTTP.get("orders")).data;
// }

// export async function one(id: string) {
//   return (await HTTP.get(`orders/${id}`)).data;
// }

// export interface OrderItem {
//   itemId: string;
//   itemName: string;
//   amount: number;
// }

export enum PickupOrDelivery {
  pickup = "pickup",
  delivery = "delivery",
}

// interface CreateOrderParamsBase {
//   resellerId?: string;
//   pickupOrDelivery: PickupOrDelivery;
//   deliveryDate: Date;
//   items: OrderItem[];
//   redirectUrl: string;
// }

// export interface CreateUserDetails {
//   userId: string;
// }

// export interface CreateUserOrderParams extends CreateOrderParamsBase, CreateUserDetails { }

// export interface CreateAnonymousUserDetails {
//   toName: string;
//   toEmailAddress: string;
//   toPhoneNumber?: string;
// }

// export interface CreateAnonymousOrderParams extends CreateOrderParamsBase, CreateAnonymousUserDetails { }

export interface CreateOrderResponse {
  paymentUrl: string;
  orderId: string;
}

// export async function create(params: CreateUserOrderParams | CreateAnonymousOrderParams) {
//   return (await HTTP.post("orders", params)).data as CreateOrderResponse;
// }

export interface OrderItem {
  id: string;
  amount: number;
}

interface CreateOrderParamsBase {
  delivery_date: string;
  items: OrderItem[];
  name: string;
  email_address: string;
  redirect_url: string;
  pickup_or_delivery: PickupOrDelivery;
}

interface CreateDeliveryOrderParams {
  phone_number?: string;
  street_address: string;
  city: string;
}

export async function create(
  params: CreateOrderParamsBase | CreateDeliveryOrderParams
) {
  return (await HTTP.post("addorder.php", params)).data as CreateOrderResponse;
}
import { createStore } from "vuex";

import cart from "./cart";
import customer from "./customer";
import items from "./items";
import viewstate from "./viewstate";

export default createStore({
  modules: {
    cart,
    customer,
    items,
    viewstate,
  },
});

<template>
  <router-view/>
</template>


<style lang="scss">
@import "@/styles/reset";
@import "@/styles/fonts";
@import "@/styles/theme";

html,
body {
  @include font-inter;
  overflow-x: hidden;
  background-color: $dark;
  color: $light;
  min-height: 100vh;
}

#app {
  overflow: hidden;
}

a {
	color: $brand;
}

b {
  text-transform: uppercase;
  font-weight: 800;
}

h1 {
  @include font-bree;
  font-size: clamp(32px, 4vmin, 56px);
  line-height: clamp(40px, 10vmin, 64px);
  text-align: center;
}

h2 {
  @include font-bree;
  font-size: clamp(28px, 2.3vmin, 40px);
  line-height: clamp(32px, 3vmin, 80px);
}

h3 {
  @include font-inter;
  font-size: clamp(20px, 2.3vmin, 40px);
  line-height: clamp(32px, 3vmin, 80px);
  text-align: center;
}

h4 {
  @include font-bree;
  font-size: clamp(20px, 2.3vmin, 24px);
  line-height: clamp(32px, 3vmin, 40px);
}

p {
  line-height: 1.5em;
  margin: 1em 0;

  &.accented {
    font-weight: 600;
  }
}
</style>

<style lang="scss" scoped>
@import "@/styles/theme";

a {
  margin: 0 4px;
}
</style>
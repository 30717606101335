import { Module } from 'vuex';

export enum View {
  none = "none",
  sausageSelector = "sausageSelector",
  cartOverview = "cartOverview",
  cartDetails = "cartDetails",
  customerDetails = "customerDetails",
  contact = "contact",
  contactDone = "contactdone",
}

export type DefaultSausageType = "regular" | "cheese" | "jalapenocheese" | "spicycheese";

interface State {
  view: View;
  defaultSausageType?: DefaultSausageType;
  itemGuid?: string;
}

let ignoreClose = false;

const viewstate: Module<State, never> = {
  namespaced: true,
  state: () => ({
    view: View.none,
  }),
  getters: {
    view: (state) => state.view,
    defaultSausageType: (state) => state.defaultSausageType,
    itemGuid: (state) => state.itemGuid,
  },
  mutations: {
    setView: (state, to: View) => {
      ignoreClose = true;
      state.view = to;

      setTimeout(() => { ignoreClose = false; }, 100);
    },
    setDefaultSausageType: (state, to?: DefaultSausageType) => state.defaultSausageType = to,
    setItemGuid: (state, to?: string) => state.itemGuid = to,
  },
  actions: {
    configure({ commit }, type?: DefaultSausageType) {
      commit("setDefaultSausageType", type);
      commit("setItemGuid");
      commit("setView", View.sausageSelector);
    },
    edit({ commit }, guid: string) {
      commit("setItemGuid", guid);
      commit("setDefaultSausageType");
      commit("setView", View.sausageSelector);
    },
    close({ commit, rootState }) {
      // when a new popup is opened, the old popup fires an unimportant close event
      if (ignoreClose) { return; }
      
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      if ((rootState as any).cart.items.length) {
        commit("setView", View.cartOverview);
      } else {
        commit("setView", View.none);
      }
    }
  },
}

export default viewstate;

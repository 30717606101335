import { HTTP } from ".";

export interface Item {
  id: string;
  name: string;

  priceInCents: number;

  meatType: "pig" | "chicken";
  weight: "250g" | "500g" | "1kg";
  variation: "regular" | "cheese" | "jalapenocheese" | "spicycheese";
}

export async function all() {
  return (await HTTP.get<Item[]>("items.php")).data;
}

import { PickupOrDelivery } from "@/api/orders";
import { Module } from 'vuex';

export interface CustomerData {
  fullName: string;
  emailAddress: string;
  phoneNumber: string;
  delivery: PickupOrDelivery;
  address: string;
}

interface State {
  data: CustomerData;
}

const customer: Module<State, never> = {
  namespaced: true,
  state: () => ({
    data: {
      fullName: "",
      emailAddress: "",
      delivery: PickupOrDelivery.pickup,
      phoneNumber: "",
      address: "",
    },
  }),
  getters: {
    details: (state) => state.data,
  },
  mutations: {
    update: (state, to: Partial<CustomerData>) => {
      state.data = { ...state.data, ...to };
    }
  },
}

export default customer;

import { all, Item as ApiItem } from "@/api/items";
import { Module } from 'vuex';

export type Item = ApiItem;

interface State {
  items: Item[];
  fetching: boolean;
}

const items: Module<State, never> = {
  namespaced: true,
  state: () => ({
    items: [],
    fetching: false,
  }),
  getters: {
    byId: (state) => (id: string) => state.items.find((i) => i.id === id),
    all: (state) => state.items,
  },
  mutations: {
    setItems: (state, to: Item[]) => {
      state.items = to;
      state.fetching = false;
    },
    setFetching: (state, to: boolean) => state.fetching = to,
  },
  actions: {
    async fetch({ commit, state }) {
      if (state.fetching) { return; }

      commit("setFetching", true);
      const items = await all();
      commit("setItems", items);
    }
  }
}

export default items;

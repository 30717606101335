import { OrderItem } from "@/api/orders";
import { Module } from 'vuex';

export interface CartItem extends OrderItem {
  clientPriceInCents: number;
}

interface State {
  items: CartItem[],
}

const cart: Module<State, never> = {
  namespaced: true,
  state: () => ({
    items: [],
  }),
  getters: {
    items: (s) => s.items,
    amountTypes: (s) => s.items.length,
    amountItems: (s) => {
      let total = 0;
      s.items.forEach((i) => { total += i.amount; });
      return total;
    },
    totalPriceInCents: (s) => {
      let total = 0;
      s.items.forEach((i) => { total += (i.amount * i.clientPriceInCents); });
      return total;
    },
    byId: (s) => (id: string) => s.items.find((i) => i.id === id),
  },
  mutations: {
    add(state, item: CartItem) {
      const exists = state.items.find((i) => i.id === item.id);
      if (exists) {
        exists.amount += item.amount;
      } else {
        state.items.push(item);
      }
    },
    update(state, { id, item } : { id: string, item: CartItem }) {
      const index = state.items.findIndex((i) => i.id === id);
      state.items.splice(index, 1, item);
    },
    remove(state, itemId: string) {
      state.items = state.items.filter((i) => i.id !== itemId);
    },
    increase(state, itemId: string) {
      const item = state.items.find((i) => i.id === itemId);
      if (!item) { throw new Error(`Item with id ${itemId} does not exist in cart.`); }
      item.amount++;
    },
    decrease(state, itemId: string) {
      const item = state.items.find((i) => i.id === itemId);
      if (!item) { return; }

      if (item.amount === 1) {
        state.items = state.items.filter((i) => i.id !== itemId);
      } else {
        item.amount--;
      }
    },
    setAmount(state, { itemId, amount }: { itemId: string, amount: number }) {
      const item = state.items.find((i) => i.id === itemId);
      if (!item) { return; }
      
      if (amount === 0) {
        state.items = state.items.filter((i) => i.id !== itemId);
      } else {
        item.amount = amount;
      }
    },
    clear: (state) => state.items = [],
  }
};

export default cart;
